<template>
  <v-container class="payment-support-transparency" fluid>
    <v-row class="payment-section">
      <v-col cols="12" md="4" class="payment-option">
        <img src="@/assets/Icone pagamento.png" alt="Pagamento seguro" class="icon">
        <h2>Pagamento seguro</h2>
        <p>Diversas formas de pagar, todas com selo de segurança.</p>
      </v-col>
      <v-col cols="12" md="4" class="payment-option">
        <img src="@/assets/Icone suporte.png" alt="Suporte humanizado" class="icon">
        <h2>Suporte humanizado</h2>
        <p>Conta com um time de especialistas sempre dispostos a ajudar.</p>
      </v-col>
      <v-col cols="12" md="4" class="payment-option">
        <img src="@/assets/Icone ttranparência.png" alt="100% transparência" class="icon">
        <h2>100% transparência</h2>
        <p>Mostramos todos os detalhes, te deixando tranquilo.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PaymentSupportTransparency"
};
</script>

<style scoped>
.payment-support-transparency {
  background-image: url('@/assets/background/Landing-page-Franqueados-Beeasy_03.png');
  background-size: cover;
  background-position: center;
  color: black;
  display: flex;
  align-items: center;
  text-align: center;
  padding-inline: 5%;
}

.payment-section {
  margin-top: 40px;
  height: 50%;
  align-items: baseline;
}

.payment-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.payment-option p {
  margin: 0;
}

.icon {
  width: 80px;
  height: auto;
  margin-bottom: 20px;
}

h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

p {
  font-size: 1.1rem;
}
</style>
