<template>
  <v-container class="how-it-works" fluid>
    <div class="faixa">
      <img src="@/assets/Faixa.png" class="faixa-img" alt="">
    </div>
    <v-row class="align-center content how-it-works-section">
      <v-col cols="12" md="6" class="text-section">
        <h1>COMO <span class="highlight">FUNCIONA?</span></h1>
        <p>Oferecemos em nossa plataforma diversas opções de franquias para você escolher e assinar um plano. Selecione
          os produtos, personalize seu site e pronto já pode vender.</p>
      </v-col>
      <v-col cols="12" md="6" class="image-section">
        <img src="@/assets/Imagem Marketing.png" alt="Como Funciona" class="main-image">
      </v-col>
    </v-row>

    <!-- Segunda seção: Crie, Venda e Receba -->
    <v-row class="steps-section text-center">
      <v-col cols="12">
        <h1>CRIE, VENDA E <span class="highlight">RECEBA!</span></h1>
      </v-col>
      <v-col cols="12" md="4" class="step">
        <img src="@/assets/Icone crie.png" alt="Crie sua loja" class="step-icon">
        <h2>Crie sua loja</h2>
        <p>Configure sua loja em poucos cliques e tenha seus primeiros pedidos.</p>
      </v-col>
      <v-col cols="12" md="4" class="step">
        <img src="@/assets/Icone venda.png" alt="Venda seus produtos" class="step-icon">
        <h2>Venda seus produtos</h2>
        <p>Escolha os produtos que mais te agradam, personalize e venda do seu jeito!</p>
      </v-col>
      <v-col cols="12" md="4" class="step">
        <img src="@/assets/Icone receba.png" alt="Receba seu dinheiro" class="step-icon">
        <h2>Receba seu dinheiro</h2>
        <p>Vendeu, recebeu. Transparência e segurança máxima em todos os pagamentos.</p>
      </v-col>
    </v-row>

    <!-- Terceira seção: Maior Variedade de Franquias -->
    <v-row class="variety-section text-center" ref="franquias" id="franquias">
      <v-col cols="12">
        <h1><span class="highlight">MAIOR VARIEDADE</span> DE FRANQUIAS</h1>
        <p class="final-text">Explore a nossas opções de franquias com <span class="highlight">diversos produtos para
            você escolher e
            vender.</span></p>
      </v-col>
    </v-row>
    <v-container class="container-dinamic">
      <v-row class="customers-list">
        <v-col cols="12" sm="4" lg="3" class="customer-logo">
          <img src="../assets/logos-clientes/logo-graminha.png" alt="">
        </v-col>
        <v-col cols="12" sm="8" lg="9">
          <div class="customers-datas">
            <h5>
              Bruno Graminha - 
              <a href="https://www.brunograminha.com.br" target="_blank">
                www.brunograminha.com.br
              </a>
            </h5>

            <p>
              Bruno Graminha é uma marca consolidada no mercado há 6 anos, especializada na venda de roupas, sapatos e acessórios.<br>
            </p>

            <v-btn class="cta-button" color="yellow" href="https://painel.brunograminha.com" target="_blank"><strong>Seja um franqueado</strong></v-btn>

          </div>
          <br>
        </v-col>


        <v-col cols="12" sm="4" lg="3" class="customer-logo">
          <img src="../assets/logos-clientes/logo-2w.png" alt="">
        </v-col>
        <v-col cols="12" sm="8" lg="9">
          <div class="customers-datas">
            <h5>
              Óculos 2W - 
              <a href="https://oculos2w.com.br/" target="_blank">
                www.oculos2w.com.br
              </a>
            </h5>

            <p>
             
              A Óculos 2W é uma empresa especializada em fornecer armações de óculos e óculos solares para revenda, oferecendo personalização de produtos, incluindo armações, cases e flanelas, de acordo com a identidade da sua marca.<br>
            </p>

            <v-btn class="cta-button" color="yellow" href="https://api.whatsapp.com/send/?phone=5511978325116&text=Quero%20ser%20um%20franqueado%20da%202W" target="_blank"><strong>Seja um franqueado</strong></v-btn>

          </div>
          <br>
        </v-col>


        <v-col cols="12" sm="4" lg="3" class="customer-logo">
          <img src="../assets/logos-clientes/logo-cinto.png" alt="">
        </v-col>
        <v-col cols="12" sm="8" lg="9">
          <div class="customers-datas">
            <h5>
              Cintos Julio Almeida - 
              <a href="https://cintosjulioalmeida.com.br/" target="_blank">
                www.cintosjulioalmeida.com.br
              </a>
            </h5>

            <p>
              A Cintos Julio Almente é uma loja que oferece a maior variedade de cintos do mercado, garantindo opções para todos os estilos e necessidades.<br>
            </p>

            <v-btn class="cta-button" color="yellow" href="https://painel.cintosjulioalmeida.com/" target="_blank"><strong>Seja um franqueado</strong></v-btn>

          </div>
          <br>
        </v-col>

        <v-col cols="12" sm="4" lg="3" class="customer-logo">
          <img src="../assets/logos-clientes/logo-camiseta.png" alt="">
        </v-col>
        <v-col cols="12" sm="8" lg="9">
          <div class="customers-datas">
            <h5>
              Camisetas 12 Horas - 
              <a href="https://camisetasem12h.com.br/" target="_blank">
                www.camisetasem12h.com.br
              </a>
            </h5>

            <p>
              Camisetas em 12 Horas é uma loja que oferece camisetas personalizadas com entrega rápida em até 12 horas. Com 15 anos de experiência, permite diversas opções de personalização. O processo de orçamento é simples e conta com suporte para design. Tenha sua própria marca de roupas. Escolha a Camisetas em 12 Horas para agilidade e qualidade!<br>
            </p>

            <v-btn class="cta-button" dark color="yellow" disabled href="https://painel.oculos2w.com" target="_blank"><strong>EM breve</strong></v-btn>

          </div>
          <br>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>

export default {
  name: "HowItWorksAndSteps"
};
</script>

<style scoped>
.how-it-works {
  background-image: url('@/assets/background/Landing-page-Franqueados-Beeasy_02.png');
  background-size: cover;
  background-position: center;
  color: white;
  padding: 0;
}

.faixa {
  height: auto;
  width: 100%;
  position: relative;
  top: 0;
  display: flex;
}

.faixa-img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.content {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  position: relative;
}

.image-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  width: 100%;
}

.main-image {
  width: 100%;
  max-width: 500px;
  height: auto;
}

.text-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  position: relative;
  z-index: 1;
  padding-block: 20px;
  padding-inline: 5%;
}

.text-section h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.text-section p {
  font-size: 1.25rem;
  margin-bottom: 20px;
}

.highlight {
  color: yellow;
}

.steps-section {
  margin-bottom: 40px;
  padding-inline: 5%;
}

.steps-section h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 40px;
}

.step {
  text-align: center;
  margin-bottom: 20px;
}

.step-icon {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.step h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.step p {
  font-size: 1.1rem;
}

.variety-section {
  /* padding-bottom: 40px; */
}

.variety-section h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.variety-section p {
  font-size: 1.25rem;
  margin-bottom: 40px;
}

.franchise-adjust {
  width: 100%;
  display: flex;
  justify-content: center;
}

.franchise-option {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  max-width: 50%;
}

.franchise-box {
  width: 80%;
  max-width: 350px;
  height: 500px;
  background-color: white;
  border-radius: 20px;
  margin-inline: 5%;
}

.first {
  justify-content: end;
}

.last {
  justify-content: start;
}

@media (max-width: 600px) {
  .text-section {
    align-items: center;
    text-align: center;
    padding: 20px;
    margin-top: 32px;
  }

  .text-section h1 {
    font-size: 1.5rem;
  }

  .text-section p {
    font-size: 0.875rem;
  }

  .steps-section h1,
  .variety-section h1 {
    font-size: 1.5rem;
  }

  .step h2 {
    font-size: 1.25rem;
  }

  .step p,
  .variety-section p {
    font-size: 0.875rem;
  }

  .final-text {
    padding-inline: 10%;
  }
}
.container-dinamic{
  width: 50%;
}


.customers-list {
  margin-top: 40px;
}

.customer-logo img {
  width: 100%;
  height: auto;
}


.customers-datas h5{
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.customers-datas p{
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.cta-button-f {
  background-color: #fff;
  color: black;
  font-weight: bold;
  padding: 12px 30px;
  font-size: 1.2rem;
  width: auto;
}


@media (max-width: 768px) {
  .franchise-adjust {
    flex-direction: column;
    height: 900px;
    margin-block: 30px;
  }

  .franchise-option {
    max-width: 100%;
    max-height: 500px;
  }

  .first {
    justify-content: center;
  }

  .last {
    justify-content: center;
  }

  .franchise-box {
    height: 100%;
  }

  .container-dinamic{
    width: 100%
  }

  .customers-datas h5{
    font-size: 1rem;
  }

  .customers-datas p{
    font-size: 0.875rem;
  }
}



</style>
