<template>
  <v-container fluid class="financial-control-section">
    <!-- Projeção de Faturamento Section -->
    <h3 class="projection-title">Quer saber quanto você pode <strong style="color: yellow;">faturar?</strong></h3>
    <v-row class="fill-height projection-section">
      <v-col cols="12" class="text-center projection-col">
        <div class="projection-card">
          <p class="projection-header">Projeção de Faturamento</p>
          <div class="projection-content">
            <div class="projection-item">
              <span class="item-label">Preço de custo</span>
              <span>R$ 40,00</span>
            </div>
            <div class="projection-item slider-container">
              <span class="item-label">Vendas pelo preço de</span>
              <v-slider v-model="price" :min="40" :max="100" :step="1" class="slider" thumb-label></v-slider>
              <span>{{ price.toFixed(2) }}</span>
            </div>
            <div class="projection-item slider-container">
              <span class="item-label">Vendas por dia</span>
              <v-slider v-model="salesPerDay" :min="1" :max="30" :step="1" class="slider" thumb-label></v-slider>
              <span>{{ salesPerDay }} dias</span>
            </div>
            <v-divider class="pb-4"></v-divider>
            <div class="projection-item">
              <span class="item-label">Lucro por venda</span>
              <span>R$ {{ profitPerSale.toFixed(2) }}</span>
            </div>
            <div class="projection-result">
              <span>Em 30 dias você ganhará</span>
            </div>
            <span class="result-value">R$ {{ projectedEarnings.toLocaleString() }}</span>
          </div>
        </div>
        <v-btn class="cta-button" color="yellow" href="https://api.whatsapp.com/send?l=pt&phone=5511978325116"
          target="_blank">
          Quero minha loja!
        </v-btn>
      </v-col>
    </v-row>

    <!-- Escolha a Assinatura Perfeita Section -->
    <v-row class="subscription-section">
      <v-col cols="12" md="6" class="subscription-text">
        <h2>ESCOLHA A <span class="highlight">ASSINATURA</span> PERFEITA <br>PARA VOCÊ!</h2>
        <p>
          Sua loja hospedada na plataforma <a href="#" class="beeasy-link">Beeasy</a>, com gestão de produtos, formas de
          pagamentos, customização, tudo em um só lugar. A forma perfeita para quem busca uma solução mais simples de
          gerenciar.
        </p>
      </v-col>
      <v-col cols="12" md="6" class="subscription-card">
        <div class="card">
          <p>Franquias a partir de</p>
          <h3>R$ 99,90/mês</h3>
          <ul>
            <li>
              <v-icon left>mdi-check-circle</v-icon>
              Links ilimitados
            </li>
            <li>
              <v-icon left>mdi-check-circle</v-icon>
              Plataforma própria
            </li>
            <li>
              <v-icon left>mdi-check-circle</v-icon>
              Suporte
            </li>
            <li>
              <v-icon left>mdi-check-circle</v-icon>
              Hashtags otimizadas
            </li>
            <li>
              <v-icon left>mdi-check-circle</v-icon>
              Usuários ilimitados
            </li>
          </ul>
          <v-btn class="cta-button" color="yellow">Ver Franquias</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'FinancialControlSection',
  data() {
    return {
      price: 72,
      salesPerDay: 15,
      cost: 40,
    };
  },
  computed: {
    profitPerSale() {
      return this.price - this.cost;
    },
    projectedEarnings() {
      return this.profitPerSale * this.salesPerDay * 30;
    },
  },
};
</script>

<style scoped>
.financial-control-section {
  background-image: url('@/assets/background/Landing-page-Franqueados-Beeasy_04.png');
  background-size: cover;
  background-position: center;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-inline: 5%;
  padding-block: 20px;
}

.image-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.financial-image {
  max-width: 100%;
  height: auto;
}

.details-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  padding: 20px;
}

.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.description {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.projection-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #FFFFFF;
}

.projection-section {
  padding: 60px 0;
  width: 90%;
  max-width: 800px;
}

.projection-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projection-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  color: black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  /* Ajustar a largura do cartão de projeção */
}

.projection-header {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.projection-content {
  display: flex;
  flex-direction: column;
}

.projection-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.slider-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.item-label {
  flex: 1;
  text-align: left;
}

.slider {
  flex: 4;
  margin: 0 10px;
}

.projection-result {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
}

.result-value {
  color: #FFC107;
  font-size: 2rem;
}

.cta-button {
  background-color: #FFC107;
  color: black;
  font-weight: bold;
  padding: 12px 30px;
  font-size: 1.2rem;
  width: auto;
}

/* Subscription Section */
.subscription-section {
  padding: 40px 0;
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.subscription-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
}

.subscription-text h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.subscription-text .highlight {
  color: yellow;
}

.subscription-text p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.subscription-text .beeasy-link {
  color: #00FF7F;
  text-decoration: none;
}

.payment-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subscription-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.card p {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #4b4949;
}

.card h3 {
  font-size: 2rem;
  color: #00FF7F;
  margin-bottom: 20px;
}

.card ul {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
}

.card ul li {
  font-size: 1rem;
  color: #4b4949;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.card ul li v-icon {
  margin-right: 10px;
}

.card .cta-button {
  background-color: #FFC107;
  color: black;
  font-weight: bold;
  padding: 12px 30px;
  font-size: 1rem;
  width: 100%;
}

/* Estilos para dispositivos móveis */
@media (max-width: 768px) {
  .financial-control-section {
    height: auto;
    padding: 20px;
    padding-inline: 10%;
    padding-block: 20px;
  }

  .projection-section {
    padding: 0;
    padding-bottom: 60px;
  }

  .section-title {
    font-size: 2rem;
  }

  .description {
    font-size: 1rem;
  }

  .projection-title {
    font-size: 1.7rem;
    text-align: center;
    margin-top: 40px;
  }

  .projection-card {
    padding: 15px;
  }

  .cta-button {
    font-size: 0.6rem;
    padding: 10px 20px;
  }

  .subscription-text h2 {
    font-size: 1.5rem;
  }

  .subscription-text p {
    font-size: 1rem;
  }

  .card h3 {
    font-size: 1.5rem;
  }

  .card ul li {
    font-size: 0.8rem;
  }
}
</style>
